@use 'mixins';

.HomePage {
    .promoted {
        margin-bottom: var(--smallPadding);
        margin-left: var(--smallPadding);

        :global(.betting-disabled) & {
            display: none;
        }
    }

    @include mixins.responsive(m, below) {
        .promoted {
            margin-left: var(--standardPadding);
            font-size: 0.875em;
        }
    }
}
