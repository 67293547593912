@use 'mixins';

.AvatarList {
    display: flex;
    flex-direction: column;
    margin: var(--largePadding) var(--standardPadding);

    .title {
        padding-bottom: var(--standardPadding);
        border-bottom: 1px solid rgb(var(--sectionBorder));
    }

    .authors {
        display: flex;
        margin-left: var(--tinyPadding);
        flex-direction: row;
        margin-top: var(--standardPadding);
        text-align: center;
        overflow-x: auto;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none; // hide scrollbar for safari
        }
    }

    .author {
        margin-right: var(--largePadding);

        img {
            height: 5em;
            width: 5em;
        }

        a {
            color: inherit;
            text-decoration: none;
        }

        p {
            @extend %heading-x-small;

            margin-top: var(--standardPadding);
        }
    }

    @include mixins.responsive(m, below) {
        margin: var(--largePadding) 0;

        .title {
            padding-left: var(--standardPadding);
        }

        .authors {
            margin-left: 0;
        }

        .author {
            background-color: rgb(var(--background));
            padding: var(--standardRadius) var(--standardRadius);
            margin-right: var(--smallPadding);

            &:first-child {
                margin-left: var(--standardPadding);
            }
        }
    }
}
