@use 'mixins';

%stretch-item-image {
    .item-article,
    .item-link,
    .item-image-container {
        height: 100%;
    }

    .item-image-container {
        aspect-ratio: auto;
    }

    .item-info {
        flex: 1;
    }
}

%stretch-item-info {
    .item-article,
    .item-link,
    .item-image-container {
        height: 100%;
    }

    .item-image-container {
        flex: 0;
    }

    .item-info {
        flex: 1;
    }
}

.FeaturedCarrousel {
    ---featured-carousel-font-size-multiplier: 1;
    ---featured-carousel-font-size-minimum: 1rem;
    ---featured-carousel-large-image-clip-path: none;
    ---featured-carousel-large-padding: var(--largePadding);
    ---featured-carousel-slider-bar-height: 2em;
    ---featured-carousel-slider-bar-justify-content: start;
    ---featured-carousel-no-caption-box-item-background: linear-gradient(
        180deg,
        transparent 50%,
        rgb(var(--invertedBackground)) 90%
    );

    :global(.theme-bi) & {
        ---featured-carousel-slider-bar-justify-content: center;
    }

    :global(.theme-br) & {
        ---featured-carousel-large-image-clip-path: polygon(0 0, 100% 0, 100% 85%, -2% 100%);
    }

    :global(.theme-mh) & {
        ---featured-carousel-slider-bar-justify-content: center;
    }

    :global(.theme-vi) & {
        ---featured-carousel-large-padding: var(--standardPadding);
        ---featured-carousel-no-caption-box-item-background: linear-gradient(
            180deg,
            transparent 60%,
            rgb(var(--invertedBackground)) 70%
        );
    }
}

.FeaturedCarrousel {
    position: relative;
    margin-bottom: var(---featured-carousel-large-padding);
}

.item-tag,
.pro-tag-container {
    position: absolute;
    top: var(--standardPadding);
    right: var(--standardPadding);
}

.items {
    display: block;
}

.items-slideshow {
    @include mixins.horizontalScrollSnapOneElement;
    display: flex;

    .item {
        @extend %stretch-item-info;
        @include mixins.horizontalScrollSnapOneElementItem;
        height: auto;
    }

    .item,
    .item.item-size-large {
        .item-info {
            padding: var(--standardPadding) var(--standardPadding) var(---featured-carousel-slider-bar-height);
        }
    }
}

.items-expanded-footer {
    display: flex;
    flex-wrap: wrap;
    gap: var(---featured-carousel-large-padding);

    .item {
        &:nth-child(1) {
            width: 100%;
        }

        &:nth-child(n + 2) {
            @extend %stretch-item-info;
            flex: 1;
            width: calc(1 / 3 * 100%);
        }
    }
}

.items-expanded-side {
    display: grid;
    grid-template-columns: calc(2 / 3 * 100%) 1fr;
    grid-template-rows: 1fr 1fr;
    gap: var(---featured-carousel-large-padding);

    .item {
        &:nth-child(1) {
            grid-area: 1 / 1 / 3 / 2;
        }

        &:nth-child(2) {
            @extend %stretch-item-image;
            grid-area: 1 / 2 / 2 / 3;
        }

        &:nth-child(3) {
            @extend %stretch-item-image;
            grid-area: 2 / 2 / 3 / 3;
        }
    }
}

.items-minified-side {
    position: relative;

    > .item:first-child {
        .item-info {
            width: 65%;
        }

        .item-link {
            position: relative;

            // background gradient bottom and right
            &::after {
                content: '';
                pointer-events: none;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background:
                    linear-gradient(180deg, transparent 50%, rgb(var(--invertedBackground)) 90%),
                    linear-gradient(90deg, transparent 50%, rgb(var(--invertedBackground)) 90%);
            }
        }
    }

    .side-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 65%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;
        border-left: 1px solid rgba(var(--invertedTextColor), 0.1);

        .item {
            padding: var(--standardPadding);
            border-bottom: 1px solid rgba(var(--invertedTextColor), 0.1);

            &:first-child {
                border-top: 1px solid rgba(var(--invertedTextColor), 0.1);
            }
        }
    }
}

.items-twin-mains {
    display: flex;
    gap: var(---featured-carousel-large-padding);

    .item {
        width: 50%;
    }
}

.item {
    display: block;
    overflow: hidden;
}

.item-size-large {
    ---featured-carousel-font-size-multiplier: 0.5;
    ---featured-carousel-font-size-minimum: 1.25rem;

    .item-info {
        padding: var(---featured-carousel-large-padding);
    }
}

.item-variant-bottom-caption-box,
.item-variant-bottom-caption-box-inverted {
    .item-link {
        display: flex;
        flex-direction: column;
    }

    .item-tag {
        position: absolute;
        top: -1.8em;
        left: 50%;
        transform: translateX(-50%);
    }

    &.item-size-large {
        .item-info {
            padding-top: var(--standardPadding);
            padding-bottom: var(--standardPadding);
        }

        .item-title {
            text-align: center;
        }
    }
}

.item-variant-bottom-caption-box {
    .item-info {
        background: rgb(255, 255, 255);
    }

    .item-title {
        color: rgb(0, 0, 0);
    }

    .item-comments {
        color: rgb(var(--lighterTextColor));
    }
}

.item-variant-bottom-caption-box-inverted {
    .item-info {
        background: rgb(0, 0, 0);
    }
}

.item-variant-floating-caption-box-inverted {
    .item-link {
        position: relative;
    }

    .item-info {
        position: absolute;
        bottom: 0;
    }

    .item-title {
        position: relative;
        padding: var(--tinyPadding);
    }

    .item-title-background {
        display: block;
        position: absolute;
        z-index: -1;
        width: calc(100% - var(--tinyPadding) * 2);

        &::before {
            content: attr(data-content);
            color: transparent;
            background-color: rgb(var(--invertedBackground));
            outline: 0.15em solid rgb(var(--invertedBackground));
        }
    }

    .desktop-container & {
        &.item-size-large {
            .item-image-container {
                clip-path: var(---featured-carousel-large-image-clip-path);
            }
        }
    }
}

.item-variant-no-caption-box {
    .item-info {
        position: absolute;
        bottom: 0;
    }

    .item-link {
        position: relative;

        // background gradient
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;
            background: var(---featured-carousel-no-caption-box-item-background);
        }
    }
}

.item-variant-minified {
    .item-link {
        display: flex;
        align-items: center;
        gap: var(--standardPadding);
    }

    .item-image-container {
        width: calc(100% / 3);
        height: 60px;
    }

    .item-info {
        position: relative;
        padding: 0;
    }

    .item-title {
        font-size: 1em;
    }

    .item-comments {
        display: none;
    }
}

.item-variant-footer {
    .item-link {
        display: flex;
        flex-direction: column;
    }

    .item-info {
        background: rgb(255, 255, 255);
    }

    .item-title {
        color: rgb(0, 0, 0);
        font-size: 1em;
    }

    .item-comments {
        margin-top: var(--smallPadding);
        color: rgb(var(--lighterTextColor));
    }
}

.item-link {
    display: block;
    text-decoration: none;
}

.item-image-container {
    position: relative;
    aspect-ratio: 5 / 3;
}

.item-info {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding: var(--standardPadding);
    container-type: inline-size;
    width: 100%;
}

.item-tag {
    margin-bottom: var(--smallPadding);
}

.item-title {
    color: rgb(var(--invertedTextColor));
    overflow-wrap: break-word;
    white-space: break-spaces;
}

.item-title-typography-article-heading-medium {
    @extend %article-heading-medium;
}

.item-title-typography-heading-medium {
    @extend %heading-medium;
}

.item-title-typography-heading-large {
    @extend %heading-large;
}

.item-title-typography-premium-heading-medium {
    @extend %premium-heading-medium;
}

.item-title-typography-link-list {
    @extend %link-list;
}

.item-title-font-size-s {
    font-size: max(
        6cqi * var(---featured-carousel-font-size-multiplier),
        var(---featured-carousel-font-size-minimum)
    );
}

.item-title-font-size-m {
    font-size: max(
        8cqi * var(---featured-carousel-font-size-multiplier),
        var(---featured-carousel-font-size-minimum)
    );
}

.item-title-font-size-l {
    font-size: max(
        9cqi * var(---featured-carousel-font-size-multiplier),
        var(---featured-carousel-font-size-minimum)
    );
}

.item-title-font-size-xl {
    font-size: max(
        10cqi * var(---featured-carousel-font-size-multiplier),
        var(---featured-carousel-font-size-minimum)
    );
}

.item-title-font-size-xxl {
    font-size: max(
        11cqi * var(---featured-carousel-font-size-multiplier),
        var(---featured-carousel-font-size-minimum)
    );
}

.item-title-font-size-xxxl {
    font-size: max(
        15cqi * var(---featured-carousel-font-size-multiplier),
        var(---featured-carousel-font-size-minimum)
    );
}

.item-comments {
    @extend %caption-small;

    width: fit-content;
    margin-top: min(var(--standardPadding), 2cqi);
    line-height: 1.5em;
    color: rgba(var(--invertedTextColor), 0.6);

    :global(.Icon) {
        font-size: 1.25rem;
        margin-right: var(--tinyPadding);
        vertical-align: bottom;
    }
}

.slider-bar {
    display: flex;
    gap: 0.25em;
    position: absolute;
    right: var(---featured-carousel-large-padding);
    bottom: 0;
    left: var(---featured-carousel-large-padding);
    z-index: 5;
    height: var(---featured-carousel-slider-bar-height);
    justify-content: var(---featured-carousel-slider-bar-justify-content);
}

.slider-bar-item {
    display: flex;
    align-items: center;

    &::after {
        content: '';
        display: block;
        height: 0.1875em;
        width: 1.5em;
        background-color: rgb(var(--lightSectionBack));
        transition: background-color 0.3s ease;
    }

    &:hover {
        &::after {
            background-color: rgb(var(--sectionBack));
        }
    }

    &.active {
        &::after {
            background-color: rgb(var(--mainColor));
        }
    }
}

.desktop-container {
    display: none;
}

@include mixins.responsive(m, above) {
    .mobile-container {
        display: none;
    }

    .desktop-container {
        display: block;
    }
}
