@use 'mixins';

$-border-width: 3px;

.HeadlinesDossier {
    padding: var(--largePadding);
    padding-top: 0;
    background-color: rgb(var(--invertedBackground));
    color: rgb(var(--invertedTextColor));
    border-top: $-border-width solid rgb(var(--mainColor));
    border-radius: var(--standardRadius);

    h3 {
        width: fit-content;
        margin-top: -1px;
        margin-bottom: var(--standardPadding);
        padding: var(--smallPadding) var(--standardPadding);
        background-color: rgb(var(--mainColor));
        color: rgb(var(--invertedTextColor));
        font-weight: 700;
        font-size: 1.125em;
        line-height: 1em;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        border-bottom-right-radius: var(--standardRadius);
        border-bottom-left-radius: var(--standardRadius);
    }

    h1 {
        margin-bottom: var(--tinyPadding);
    }

    .sub-title {
        display: block;
        margin-bottom: var(--largePadding);
        line-height: 1.5em;
        color: rgb(var(--lighterTextColor));
        letter-spacing: 0.2px;
    }

    h4 {
        display: inline-block;
        margin-left: var(--largePadding);
        transition: all 0.2s ease-in-out;
    }

    a {
        display: flex;
        padding: var(--standardPadding) 0;
        justify-content: space-between;
        color: rgb(var(--invertedTextColor));
        text-decoration: none;

        &:hover {
            color: inherit;

            h4 {
                text-decoration: none;
            }
        }
    }

    ul {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 2em;
            bottom: 2em;
            left: 0;
            border-left: $-border-width dashed rgb(var(--lighterTextColor));
            z-index: 1;
        }

        li {
            position: relative;
            margin: 0 calc(-1 * var(--largePadding));
            padding: 0 var(--largePadding);
            background-color: rgb(var(--invertedBackground));
            transition: all 0.15s ease-in-out;

            &:nth-child(odd) {
                background-color: rgba(var(--background), 0.05);
            }

            &:hover {
                background-color: rgb(var(--mainColor));
            }
        }
    }

    .radio {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: calc(var(--largePadding) + ($-border-width / 2));
        width: 1.25em;
        height: 1.25em;
        transform: translate(-50%, -50%);
        background-color: rgb(var(--invertedBackground));
        border: var(--standardRadius) solid rgb(var(--lighterTextColor));
        border-radius: 50%;
        z-index: 2;

        &::after {
            content: '';
            display: flex;
            position: relative;
            top: 50%;
            width: 0.5em;
            height: 0.5em;
            margin: auto;
            transform: translateY(-50%);
            background-color: rgb(var(--lighterTextColor));
            border-radius: 50%;
        }
    }

    .comments-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 3.5em;
        font-size: 1rem;
        transform: translateX(var(--smallPadding));
        opacity: 0.6;

        .comment-count,
        :global(.Icon) {
            font-size: 0.85em;
        }

        :global(.Icon) {
            margin-right: var(--tinyPadding);
        }
    }

    @include mixins.responsive(m, below) {
        h3 {
            padding: var(--tinyPadding) var(--smallPadding);
            font-size: 0.875em;
        }

        .comments-indicator {
            font-size: 1rem;
            line-height: 1.25em;
            min-width: 2em;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            :global(.Icon) {
                margin-right: 0;
            }
        }
    }
}
